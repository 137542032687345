<template>
  <b-card>
    <div class="d-flex">
      <feather-icon icon="LayersIcon" size="19"/>
      <h4 class="mb-0 ml-50">{{ dataInfo.name }}</h4>
    </div>
    <small class="text-muted">{{ dataInfo.description }}</small>

    <b-row class="py-1">
      <b-col cols="12" md="12" class="ps-xl-75 ps-0">
        <div class="d-flex align-items-center justify-content-center justify-content-lg-end flex-lg-nowrap flex-wrap">
          <add-new :is-add-new-sidebar-active.sync="isAddNewSidebarActive" :definition-base-data="dataInfo" :select-definition-data="selectDefinitionData" :language-options="languageOptions"></add-new>
          <b-button variant="primary" size="md" @click="isAddNewSidebarActive = true; selectDefinitionData={}">
            <feather-icon icon="LayersIcon"/>
            <span class="align-middle"> Yeni Kayıt</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-table :items="dataInfo.definitions" :fields="definitionFields" class="mb-2" striped responsive bordered hover>

      <template #cell(isDefault)="data">
        <boolean-column-table :data="data.value"></boolean-column-table>
      </template>

      <template #cell(isActive)="data">
        <active-column-table :data="data.value"></active-column-table>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap" v-if="!data.item.isDeleted">
          <feather-icon :id="`row-${data.item.id}-edit`" icon="EditIcon" size="16" class="mr-1" @click="changeDefinition(data.item)"/>
          <b-tooltip title="Değiştir" class="cursor-pointer" :target="`row-${data.item.id}-edit`"/>
          <feather-icon :id="`row-${data.item.id}-remove`" icon="TrashIcon" size="16" @click="deleteDefinition(data.item)"/>
          <b-tooltip title="Çıkar" class="cursor-pointer" :target="`row-${data.item.id}-remove`"/>
        </div>
      </template>

    </b-table>

    <b-row class="mt-1">
      <b-col>
        <b-button
            @click="onSubmit"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="button"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          <feather-icon icon="SaveIcon" size="16"/>
          <span class="align-middle"> Kaydet</span>
        </b-button>
        <b-button
            :to="{ name: 'system-definition-list' }"
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Vazgeç</span>
        </b-button>
      </b-col>
    </b-row>

    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BTooltip,
} from 'bootstrap-vue'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store"
import storeModule from "@/views/system/definition/store"
import languageModule from "@/store/common/language"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import Overlay from "@/components/Overlay.vue"
import {toastMessage} from "@core/utils/utils"
import {ActiveColumnTable, BooleanColumnTable} from "@/components/Table"
import AddNew from "@/views/system/definition/detail/AddNew.vue"
import Vue from 'vue'
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BTooltip,
    AddNew,
    BooleanColumnTable,
    ActiveColumnTable,
    Overlay,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_LANGUAGE_MODULE_NAME = 'store-language'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_LANGUAGE_MODULE_NAME, languageModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_LANGUAGE_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      name: '',
      description: '',
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'system-definition-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/editItem', dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'system-definition-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const definitionFields = [
      {key: 'name', label: 'İsim'},
      {key: 'key', label: 'Anahtar'},
      {key: 'sortNumber', label: 'Sıra'},
      {key: 'isDefault', label: 'Varsayılan'},
      {key: 'languageName', label: 'Dil'},
      {key: 'isActive', label: 'Durum'},
      {key: 'actions', label: 'İşlemler', sortable: false, thStyle: {width: "2%"}},
    ];

    const isAddNewSidebarActive = ref(false)
    const selectDefinitionData = ref({})
    const languageOptions = ref([])

    const changeDefinition = (dataItem) => {
      if (!$themeConfig.app.appIsDemo) {
        selectDefinitionData.value = {
          languageId: dataItem.languageId,
          languageName: dataItem.languageId === 2 ? 'İngilizce' : 'Türkçe',
          key: dataItem.key,
          name: dataItem.name,
          sortNumber: dataItem.sortNumber,
          isDefault: dataItem.isDefault,
          isActive: dataItem.isActive,
        }
        isAddNewSidebarActive.value = true
      } else {
        toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
      }
    }

    const deleteDefinition = (dataItem) => {
      if (!$themeConfig.app.appIsDemo) {
        Vue.swal({
          title: 'Onaylıyor musunuz?',
          text: 'Kayıt çıkarılacaktır!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet, Çıkar',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            if (dataItem.id > 0) {
              dataItem.isDeleted = true
            } else {
              dataInfo.value.definitions.splice(dataInfo.value.definitions.indexOf(dataItem), 1);
            }
          }
        })
      } else {
        toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
      }
    }

    busy.value = true
    store.dispatch('store-language/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        languageOptions.value.push({label: value.name, value: value.id})
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    return {
      busy,
      dataInfo,

      definitionFields,
      isAddNewSidebarActive,
      selectDefinitionData,
      languageOptions,

      onSubmit,
      changeDefinition,
      deleteDefinition
    }
  },
}
</script>